.App {
  text-align: center;
}

.navbar-gap {
  min-width: 20px;
}

.custom-navbar.navbar {
  background-color: rgba(0, 0, 0, 0.6) !important;
  backdrop-filter: blur(17px) !important;
  height: 105px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.custom-navbar .navbar-nav .nav-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 50%;
  background-color: white;
  transition: all 0.3s ease-in-out;
  transform: translateX(-50%);
}

.custom-navbar .navbar-nav .nav-link:hover::after {
  width: 80%;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-navbar .navbar-nav .nav-link {
  color: white !important;
  position: relative;
  padding: 5px 0;
  font-family: "Share Tech Mono", monospace;
}

.join-us-btn {
  display: inline-block;
  text-decoration: none;
  border-radius: 10px;
  /* font-weight: bold; */
  padding: 8px 20px;
  background-color: white;
  color: black;
  border: none;
  transition: all 0.3s ease;
  cursor: pointer;
  font-family: "Share Tech Mono", monospace;
  font-weight: bold;
}

.join-us-btn:hover {
  background-color: rgb(186, 233, 254);
  color: black;
  text-decoration: none;
}

.brand-container {
  margin-left: 5px;
}

.center-button {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.nav-links {
  display: flex;
  align-items: center;
  margin-right: 2px;
}

.nav-links .nav-link {
  margin-right: 8px;
}

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.custom-toggler.navbar-toggler {
  border: none;
}

.navbar-collapse {
  position: static;
}

.nav-links-container {
  margin-left: auto;
}

.page-content {
  padding-top: 80px;
  min-height: 100vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-section {
  height: 105px;
  background: linear-gradient(to right, #14286E, #49A4E6);
}

.hero-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 50px 0;
  /* height: 850px; */
  height: calc(100vh - 105px);
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.hero-text {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 24px;
  text-align: center;
  z-index: 1;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: "Share Tech Mono", monospace;
}

.hero-text:hover {
  color: rgb(186, 233, 254);
  font-weight: bold;
}

.hero-text div:first-child {
  font-size: 20px;
}

.hero-text div:last-child {
  font-size: 42px;
}

.video-mask-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.video-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: transform .5s ease;
}

.video-item:hover {
  transform: scale(1.04);
}

.mask-text {
  position: absolute;
  top: -20%;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  /* font-size: 24px; */
  font-size: 2.5vw;
  font-weight: normal;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: "Share Tech Mono", monospace;
}

.video-item:hover .mask-text {
  color: rgb(186, 233, 254);
  font-weight: bold;
}

.video-mask {
  width: 20vw;
  height: 20vw;
  /* width: 200px;
  height: 200px; */
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.video-mask video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
}

.hero-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  z-index: -1;
}

.about-section {
  margin-top: 15px;
  height: auto;
  background-color: #0000007d;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10%;
  padding-right: 10%;
  max-height: 700px;
  overflow: hidden;
  position: relative;
  margin-bottom: 30px;
}

.about-background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  z-index: -1;
}

.about-title {
  color: white;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 30px;
  font-family: "Tauri", sans-serif;
}

.about-title, .about-text p, .learn-more-btn {
  text-shadow: 1px 1px 2px rgba(0,0,0,0.8);
}

.about-content {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.about-text {
  color: white;
  flex: 1;
  padding-right: 30px;
  text-align: left;
  overflow: hidden;
}

.streetfins {
  color: #54B0F2;
  text-decoration: underline;
}

.about-text p {
  font-size: 1.5vw;
  /* line-height: 1.4; */
  font-family: "Share Tech Mono", monospace;
}

.about-images {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
}

.about-image-large {
  width: 100%;
  height: 60%;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 20px;
}

.about-image-small-container {
  display: flex;
  justify-content: space-between;
  height: 35%;
}

.about-image-small {
  width: 48%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.learn-more-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: transparent;
  color: white;
  border: 2px solid white;
  border-radius: 25px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  font-family: "Tauri", sans-serif;
  align-self: flex-start;
  text-decoration: none;
  padding: 10px 20px;
  width: fit-content;
  max-width: 200px; 
}

.learn-more-btn:hover {
  border-width: 3px;
  color: rgb(186, 233, 254);
  border-color: rgb(186, 233, 254);
}

.learn-more-btn svg {
  margin-left: 10px;
}

.team-section {
  background: linear-gradient(to bottom, #000000, #061329);
}

.team-title {
  color: white;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 30px;
  font-family: "Tauri", sans-serif;
  text-align: center;
}

.team-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 0 5%;
  max-width: 80%;
  margin: 0 auto;
}

.team-member {
  width: calc(20% - 16px);
  max-width: 250px;
  background-color: #0b234e;
  /* background-color: #004e82; */
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 180px;
}

.team-member-picture {
  width: 100%;
  height: 200px;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  height: 160px; 
}

.team-member-description {
  padding: 15px;
  text-align: center;
  color: white;
}

.team-member-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team-member-description {
  padding: 15px;
  text-align: center;
}

.team-member-description p:first-child {
  font-weight: bold;
  margin-bottom: 5px;
}

.team-member-description p:last-child {
  font-size: 0.9em;
  margin-bottom: 2px;
}

.footer-section {
  height: auto;
  min-height: 100px;
  background: linear-gradient(to bottom, #061329, #000000);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.footer-logo {
  margin-top: 60px;
  max-width: 200px;
  margin-bottom: 10px;
}

.footer-text {
  color: white;
  font-family: "Share Tech Mono", monospace;
  font-size: 14px;
  margin: 0;
}

@media (min-width: 555px) {
  .brand-container img {
    height: 50px;
    width: auto;
  }
}


@media (max-width: 1200px) {
  .team-member {
    width: calc(25% - 20px);
  }
}

@media (max-width: 991px) {

  .team-member {
    width: calc(33.33% - 20px);
  }

  .custom-navbar.navbar {
    height: 105px;
  }

  .navbar-container {
    height: 100%;
    align-items: center;
  }

  .brand-container, .center-button, .navbar-toggler {
    position: relative;
    top: auto;
    transform: none;
  }

  .center-button {
    position: absolute;
    right: 65px;
    top: 50%;
    transform: translateY(-50%);
    left: auto;
  }

  .join-us-btn {
    margin-right: 4vw; 
  }

  .navbar-toggler {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }

  #basic-navbar-nav {
    width: auto;
  }

  .nav-links {
    margin-right: 0;
    text-align: right;
  }

  .navbar-collapse {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: transparent;
  }

  .nav-links-container {
    /* margin-top: 8px; */
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(17px);
    padding: 10px;
    /* border-radius: 5px; */
    /* display: inline-block; */
  }

  .learn-more-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: transparent;
    color: white;
    border: 2px solid white;
    border-radius: 25px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    font-family: "Tauri", sans-serif;
    align-self: flex-start;
    font-size: calc(0.6rem + 0.4vw);
    padding: calc(4px + 0.5vw) calc(6px + 1vw);
  }

  .about-text p {
    font-size: 1.9vw;
    /* line-height: 1.4; */
    font-family: "Share Tech Mono", monospace;
  }
}


@media (max-width: 768px) {
  .team-member {
    width: calc(50% - 20px);
  }
}


@media (max-width: 480px) {
  .team-member {
    width: calc(100% - 20px);
  }
}


@media (max-width: 475px) {
  .top-section {
    /* height: calc(100vh - 135px); */
    height: 135px;
    background: linear-gradient(to right, #14286E, #49A4E6);
  }

  .custom-navbar.navbar {
    background-color: rgba(0, 0, 0, 0.6) !important;
    backdrop-filter: blur(17px) !important;
    height: 135px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 10px;
  }

  .navbar-container {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .brand-container {
    margin-right: auto;
    margin-left: 0;
    align-self: flex-start;
  }

  .center-button {
    position: static;
    transform: none;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 0;
    align-self: flex-start;
  }

  .navbar-toggler {
    position: absolute;
    right: 15px;
    top: 65px;
  }
}

@media (max-width: 375px) {
  .navbar-toggler {
    position: absolute;
    right: 15px;
    top: 100px;
  }
}


@media (max-width: 685px) {
  .video-mask-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    top: 0;
    transform: none;
  }

  .video-item {
    margin-bottom: 30px;
  }

  .video-item:last-child {
    margin-bottom: 88px;
  }

  .mask-text {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    margin-bottom: 10px;
    font-size: 24px;
  }

  .video-mask {
    width: 150px;
    height: 150px;
  }

  .hero-text {
    bottom: 15px;
    margin-top: 100px;
  }
}


@media (max-width: 1160px) and (min-width: 475px) {
  .custom-navbar.navbar {
    height: 105px;
  }

  .navbar-container {
    height: 100%;
    align-items: center;
  }

  .brand-container, .center-button, .navbar-toggler {
    position: relative;
    top: auto;
    transform: none;
  }

  .center-button {
    position: absolute;
    right: 65px;
    top: 50%;
    transform: translateY(-50%);
    left: auto;
  }

  .join-us-btn {
    margin-right: 4vw; 
  }

  .navbar-toggler {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }

  #basic-navbar-nav {
    width: auto;
  }

  .nav-links {
    margin-right: 0;
    text-align: right;
  }

  .navbar-collapse {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: transparent;
  }

  .nav-links-container {
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(17px);
    padding: 10px;
  }
}